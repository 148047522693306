/* stylelint-disable selector-class-pattern */

// Margin bottom
.u-mb-0 {
  margin-bottom: 0;
}

.u-mb-2 {
  margin-bottom: spacing(2);
}

.u-mb-section {
  margin-bottom: var(--section-vertical-spacing);
}

.u-mt-8 {
  margin-top: spacing(8);
}

.u-mt-12\@from-lg {
  @include from-breakpoint(lg) {
    margin-top: spacing(12);
  }
}
